import { Button, ButtonProps } from "@chakra-ui/react";
import fr from "date-fns/locale/fr";
import { ForwardedRef, forwardRef, useMemo } from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DatePicker.css";

registerLocale("fr", fr);

export enum DatePickerMode {
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
}

const modeProperties: Record<
  DatePickerMode,
  Omit<ReactDatePickerProps, "onChange">
> = {
  [DatePickerMode.DAY]: {
    dateFormat: "EEEE dd/MM/yyyy",
    scrollableYearDropdown: true,
    showMonthDropdown: true,
    showYearDropdown: true,
    yearDropdownItemNumber: 100,
  },
  [DatePickerMode.MONTH]: {
    dateFormat: "MMMM yyyy",
    scrollableYearDropdown: true,
    showMonthYearPicker: true,
    showYearDropdown: true,
    yearDropdownItemNumber: 100,
  },
  [DatePickerMode.YEAR]: {
    dateFormat: "yyyy",
    scrollableYearDropdown: true,
    showYearDropdown: true,
    showYearPicker: true,
    yearDropdownItemNumber: 100,
  },
};

interface DatePickerLabelProperties
  extends Omit<ButtonProps, "children" | "onClick"> {
  placeholder?: string;
  value?: string;
}

const DatePickerLabel = forwardRef(
  (
    { placeholder = "Sélectionner", value, ...rest }: DatePickerLabelProperties,
    reference: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Button
        color="gray.600"
        fontWeight="medium"
        ref={reference}
        textDecoration="underline"
        variant="link"
        {...rest}
      >
        {value != null && value.length > 0 ? value : placeholder}
      </Button>
    );
  },
);

export interface DatePickerProperties
  extends Pick<
      ReactDatePickerProps,
      | "endDate"
      | "isClearable"
      | "readOnly"
      | "selected"
      | "showTimeSelect"
      | "startDate"
    >,
    Omit<DatePickerLabelProperties, "onChange" | "value"> {
  mode?: DatePickerMode;
  onChange?: (value: string, date?: Date) => void;
  value?: string;
}

export const DatePicker = ({
  endDate,
  isClearable,
  isDisabled,
  mode = DatePickerMode.DAY,
  onChange,
  placeholder,
  readOnly,
  selected,
  showTimeSelect,
  startDate,
  ...rest
}: DatePickerProperties): React.ReactElement => {
  const handleChange = (date: Date) => {
    if (showTimeSelect) {
      return onChange?.(date.toISOString(), date);
    }
    return onChange?.(
      new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
      ).toISOString(),
      date,
    );
  };

  const pickerProperties = useMemo(() => {
    const base = { ...modeProperties[mode] };
    if (showTimeSelect) {
      base.dateFormat = `${base.dateFormat} HH:mm`;
    }
    return base;
  }, [mode, showTimeSelect]);

  return (
    <ReactDatePicker
      customInput={
        <DatePickerLabel
          isDisabled={isDisabled}
          pr={isClearable && selected ? 6 : undefined}
          {...rest}
        />
      }
      endDate={endDate}
      locale="fr"
      onChange={handleChange}
      placeholderText={placeholder}
      selected={selected}
      showPopperArrow={false}
      startDate={startDate}
      {...pickerProperties}
      clearButtonClassName="react-datepicker__clear-button"
      disabled={isDisabled}
      isClearable={isClearable}
      readOnly={readOnly}
      showTimeSelect={showTimeSelect}
      timeCaption="horaire"
    />
  );
};
